<template>
    <b-toast id="success-notif" toaster="b-toaster-top-center" variant="success" solid>
        <template v-slot:toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
                <i class="mdi mdi-check-circle-outline"></i>&nbsp;
                <strong class="mr-auto">&nbsp;&nbsp;Sukses!</strong>
                <small class="text-muted mr-2"></small>
            </div>
        </template>
        Data berhasil disimpan!
    </b-toast>
</template>
<script>
    export default {

    }
</script>